.Imageslider {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.carousel {
  /* background-color: white; */
  display: flex;
  margin-top: 1rem;
  width: 100rem;
  margin-left: 2rem;
  margin-right: 2rem;
  height: 40rem;
  object-fit: cover;
}
.carousel_wrapper {
  position: relative;
  width: 100%;
  height: 45%;
}
.carousel_card {
  display: flex;
  flex-direction: column;
  position: absolute;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.8);
  transition: 0.5s ease-in-out;
}
.carousel_card-active {
  opacity: 1;
  transform: scale(1);
  pointer-events: visible;
}
.card_image {
  width: 100%;
  object-fit: cover;
}
.card_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px 30px;
  align-items: flex-end;
}
.card_title {
  color: white;
  font-size: 2rem;
}
.carousel_arrow_left,
.carousel_arrow_right {
  position: absolute;
  font-size: 40px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 60%;
  padding-bottom: 7px;
  cursor: pointer;
}
.carousel_arrow_left {
  left: 15px;
}
.carousel_arrow_right {
  right: 15px;
}
