.Header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  background-color: rgb(255, 255, 255);
}
.logo {
  width: 10rem;
  height: 4rem;
  object-fit: cover;
  padding-left: 4rem;
  cursor: pointer;
}
.header-menu {
  display: flex;
  list-style: none;
  gap: 4rem;
  color: white;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 4rem;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 1rem;
  box-sizing: border-box;
}
#search {
  width: max-content;
  display: flex;
  align-items: center;
  background-color: rgb(217, 246, 253);
  width: 45rem;
}
.search-input {
  font-size: 1rem;
  font-family: "lexend", sans-serif;
  color: rgb(11, 11, 11);
  outline: none;
  border: none;
  background: transparent;
  flex: 1;
}
.login {
  display: flex;
  padding: 0px;
  color: rgb(30, 29, 29);
}
.cricle {
  padding-right: 10px;
}
.kart {
  display: flex;
  color: rgb(30, 29, 29);
}
.kartt {
  padding-right: 10px;
}
.shop {
  display: flex;
  white-space: nowrap;
  color: rgb(30, 29, 29);
}
.shopp {
  padding-right: 10px;
}
