.Flip {
  display: flex;
  justify-content: space-between;
}
.ourproducts {
  background-color: white;
  display: flex;
  margin-top: 4.5rem;
  width: 96%;
  margin-left: 2rem;
  margin-right: 2rem;
  height: 10.5rem;
}
.ourproductcontainer {
  margin: 0.5rem;
  outline: 5px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.header-products {
  display: flex;
  gap: 5rem;
}
.category {
  margin: 10px;
  list-style: none;
}

.category img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  padding-right: 3.5rem;
  padding-left: 4rem;
}

.category li {
  margin-top: 10px;
  padding-left: 5rem; /* Move content 20px to the right */
  transform: translateY(-5px); /* Move content 5px up */
  /* padding: 1rem; */
  /* padding-right: 5rem; */
}
